<template>
	<div id="edit-1099-info-modal" class="modal" aria-hidden="true">
		<div class="modal-window_overlay" tabindex="-1">
			<div @click.stop class="modal-window_container" role="dialog" aria-modal="true"
				aria-labelledby="aria-labelledby">
				<div v-if="loading" class="loader-cls">
					<div class="flex loader-main"><loader /> <div class="loader-txt">Please Wait...</div></div> 
				</div>
				<div class="modal-content" v-else>
					<slot>
						<div class="title-text">Edit 1099 INFO</div>
						<form @submit="Update1099InfoData">
							<div class="form-container">
								<div class="form-input custom-input">
									<form-input label="Total Taxable Amount" type="number" :value="info1099Data.totalTaxableAmount" :disabled="true" :labelStyle="labelStyle" width="100%" />
									<span class="right-button" @click="resetTaxableAmountToZero"><img src="@/assets/images/logos/reload.svg"/> Change to $0.00</span>
								</div>
								<form-input label="Legal First Name" type="text" v-model="info1099Data.firstName"  width="48%" :labelStyle="labelStyle" @input="checkName"/>
								<form-input label="Legal Last Name" type="text" v-model="info1099Data.lastName"  width="48%" :labelStyle="labelStyle" @input="checkName"/>
								<!-- <div class="field" style="width: 48%;"> <input type="checkbox" class="checkbox-input-deposit" v-model="editMainSSN"> Update in 1099</div> -->
								<!-- <div class="field" style="width: 48%;"></div> -->
								<form-input label="SSN" type="text" v-model="info1099Data.ssn" width="48%" :disabled="false" :labelStyle="labelStyle" @input="checkSsn" />
								<p v-if="ssnError" class="error-message ssn-error">{{ssnErrorMsg}}</p>
								<h3 class="form-sub-header">HOME ADDRESS</h3>

								<div class="suggestion-box-container">
									<form-input label="Street Name" type="text" v-model="homeAddress.address1" :labelStyle="labelStyle" :required="true"/>
									<p class="error-message" v-if="poErrorAddress">Please enter a valid home address other than PO box</p>
									<div class="box" v-if="addressSuggestions.length > 0">
										<div v-for="(item, index) in addressSuggestions" :key="index">
											<p class="suggestion-item" @click="setAddress(item.item, 'homeAddress')">{{ item.name }}</p>
										</div>
									</div>
								</div>
								<form-input label="Street Name 2 (Optional)" type="text" v-model="homeAddress.address2" placeholder="Enter Here" width="48%" :labelStyle="labelStyle" />
								<form-input label="City" type="text" v-model="homeAddress.city" placeholder="Enter Here" width="48%" :labelStyle="labelStyle" :disabled="disableAddress" :required="true"/>
								<div class="form-input">
									<state-selector :selectedState="homeAddress.state" :labelStyle="labelStyle" :disabled="disableAddress" :required="true"></state-selector>
								</div>
								<div class="form-input">
									<form-input label="Zip Code" type="text" v-model="homeAddress.postalCode" :labelStyle="labelStyle" :disabled="disableAddress" :required="true"/>
								</div>
								<form-input label="Description*" type="text" v-model="description" width="100%" :labelStyle="labelStyle" :disabled="!isDescriptionRequired"/>
								<p class="error-message" v-if="isDescriptionRequired && !(description.length)">Description is required*.</p>
							</div>
							
							<h3 class="form-sub-header">1099 ADDRESS</h3>
							<p class="form-description-text">1099 address should be same as Home address?</P>
							<InputSwitch class="input-switch" v-model="isSame1099AndDeliveryAddress" @click="toggleisSame1099AndDeliveryAddress" />

							<div v-if="!isSame1099AndDeliveryAddress" class="form-container">
								<div class="suggestion-box-container">
									<form-input label="Street Name" type="text" v-model="info1099Data.tax1099Address.address1" :labelStyle="labelStyle" :required="true"/>
									<p class="error-message" v-if="poErrorTaxAddress">Please enter a valid 1099 address other than PO box</p>
									<div class="box" v-if="taxAddressSuggestions.length > 0">
										<div v-for="(item, index) in taxAddressSuggestions" :key="index">
											<p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
										</div>
									</div>
								</div>
								<form-input label="Street Name 2 (Optional)" type="text" v-model="info1099Data.tax1099Address.address2" placeholder="Enter Here" width="48%" :labelStyle="labelStyle"/>
								<form-input label="City" type="text" v-model="info1099Data.tax1099Address.city" placeholder="Enter Here" width="48%" :labelStyle="labelStyle" :disabled="disableTaxAddress"/>
								<div class="form-input">
									<state-selector :selectedState="info1099Data.tax1099Address.state" :labelStyle="labelStyle" :disabled="disableTaxAddress"></state-selector>
								</div>
								<div class="form-input">
									<form-input label="Zip Code" type="text" v-model="info1099Data.tax1099Address.postalCode" :labelStyle="labelStyle" :disabled="disableTaxAddress"/>
								</div>
								<div class="form-input"></div>
							</div>

							<div>
								<h3 class="form-sub-header bold">1099 NAME</h3>
								<p class="form-description-text">1099 name should be the same as Driver Profile?</P>
								<InputSwitch class="input-switch" v-model="updateMainName" @click="toggleNameCorrected" />
							</div>

							<div class="flags">
								<h6>1099 Delivery Method</h6>
								<div class="flex switch-cls">
									<InputSwitch class="input-switch" v-model="physicalDelivery" @click="togglePhysicalDelivery" />
									<label>Physical Delivery</label>
								</div>
								<div class="flex switch-cls">
									<InputSwitch class="input-switch" v-model="refileIRS" @click="toggleRefileIRS" />
									<label>Refile IRS</label>
								</div>
							</div>
							<div class="footer-buttons">
								<button class="button" type="submit" :disabled="isSaveDisabled">Save & Approve</button>
								<button class="button secondary" type="button" @click="closeModal">Cancel</button>
							</div>
						</form>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import InputSwitch from 'primevue/inputswitch';
import { restApi } from "@/http/http.config";
import { encodeWithParam,encode } from "@/filters";
  import { Loader } from "@/components";
import FormInput from "@/components/forms/fields/FormInput";
import StateSelector from "@/components/forms/StateSelector";
import { isEqual, debounce } from 'lodash';
import { encrypt } from "@/filters";


export default {
	name: "Edit1099Info",
	components: { InputSwitch, FormInput, StateSelector,Loader },
	props: {
		data: {
			type: Object,
			required: true,
			default: () => { },
		},
		address:  {
			type: Object,
			required: true,
			default: () => { },
		},
		userID: {
      		type: String,
      		required: true,
    },
	},
	data: function () {
		return {
			physicalDelivery: false,
			refileIRS: false,
			fetchingAddresses: false,
			disableAddress: true,
			addressSuggestions: [],
			selectedAddress: "",
			poErrorAddress:false,
			disableTaxAddress: true,
			taxAddressSuggestions: [],
			selectedTaxAddress: "",
			poErrorTaxAddress:false,
			isSame1099AndDeliveryAddress: true,
			is1099InfoUpdated: false,
			info1099Data: {
				name: '',
				firstName:'',
				lastName:'',
                ssn: '',
                tax1099Address: {
					address1: '',
					address2: '', 
					city : '',
					state : '',
					country : '',
					postalCode : '',
				},
                totalTaxableAmount: 0,
                year: ''
			},
			homeAddress: {
				address1: '', 
                address2: '', 
                city : '',
                state : '',
                country : '',
                postalCode : '',
			},
			description: '',
			labelStyle: {
				marginBottom: '5px',
				display: 'inline-block',
			},
			addressNotFound: false,
			isSame1099AndDeliveryAddressFlagChanged: false,
			loading: false,
			ssnError:false,
			ssnErrorMsg:'',
			ssnCorrected:false,
			updateMainName:false,
			editMainSSN:false,
			updateName:false,
			isNameSync:false,
		};
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler: function (newVal) {
				if (newVal) {
					this.info1099Data = {
						name: newVal.name,
						firstName:newVal.firstName,
						lastName:newVal.lastName,
						ssn: newVal.ssn,
						tax1099Address: {
							address1: newVal.tax1099Address.address1,
							address2: newVal.tax1099Address.address2, 
							city : newVal.tax1099Address.city,
							state : newVal.tax1099Address.state,
							country : newVal.tax1099Address.country,
							postalCode : newVal.tax1099Address.postalCode,
							
						},
						year: newVal.year,
						totalTaxableAmount: newVal.totalTaxableAmount,
					};
					this.isSame1099AndDeliveryAddress = newVal.isSame1099AndDeliveryAddress;
				}
			}
		},
		address: {
			deep: true,
			immediate: true,
			handler: function (newVal) {
				if (newVal) {
					this.homeAddress = {
						address1: newVal.address1,
						address2: newVal.address2, 
						city : newVal.city,
						state : newVal.state,
						country : newVal.country,
						postalCode : newVal.postalCode,
					};
				}
			}
		},
		'homeAddress.address1' : {
			deep: true,
			handler: function (newText, oldText) {
				this.poCheck(newText, 'home');
				this.handleAddressChange(oldText, newText, "homeAddress")
			}
		},
		'info1099Data.tax1099Address.address1' : {
			deep: true,
			handler: function (newText, oldText) {
				this.poCheck(newText, 'tax');
				this.handleAddressChange(oldText, newText)
			}
		},
		homeAddress: {
			deep: true,
			handler: function(newVal) {
				this.checkIs1099InfoUpdated(newVal, "homeAddress");
			}
		},
		info1099Data: {
			deep: true,
			handler: function(newVal) {
				this.checkIs1099InfoUpdated(newVal.tax1099Address, "tax1099Address");
			}
		},
		isSame1099AndDeliveryAddress: function(newVal) {
			if(this.$props.data.isSame1099AndDeliveryAddress === false && newVal === true) {
				this.isSame1099AndDeliveryAddressFlagChanged = true;
			} else if(this.$props.data.isSame1099AndDeliveryAddress === newVal) {
				this.isSame1099AndDeliveryAddressFlagChanged = false;
			}
		}
	},
	computed: {
		partnerID() {
            return this.$route.params.id;
        },
		isSaveDisabled() {
			return Boolean(!(this.is1099InfoUpdated || this.isSame1099AndDeliveryAddressFlagChanged || this.physicalDelivery || this.refileIRS || this.ssnCorrected ||  this.updateName || this.isNameSync) || this.isTaxAddressChanged || this.isAddressLoadingOrError || (this.isDescriptionRequired ? Boolean(!this.description?.length) : false) || this.loading || this.ssnError || Boolean(!(this.info1099Data.firstName.length > 2 && this.info1099Data.lastName.length > 1))) ;
		},
		isAddressLoadingOrError() {
			return  this.poErrorAddress || this.poErrorTaxAddress || this.fetchingAddresses || this.addressSuggestions.length || this.taxAddressSuggestions.length || this.addressNotFound;
		},
		isDescriptionRequired() {
			return this.is1099InfoUpdated || this.isSame1099AndDeliveryAddressFlagChanged ||  this.ssnCorrected || this.updateName || this.isNameSync
		},
		isTaxAddressChanged(){
			if(!this.isSame1099AndDeliveryAddress){
			return Boolean(!(this.info1099Data.tax1099Address?.address1.length>1))
			}
			else{
				return false;
			}
		}
	},
	methods: {
		getAddressSuggestions(searchText, addressType) {
			this.fetchingAddresses = true;
			this.addressNotFound = false;
			const searchParams = encodeWithParam({ search: searchText });
			restApi.post("/employee/getAddressSuggestions", searchParams)
				.then(({ data }) => {
					if (data.result.length > 0) {
						let suggestions = data.result.map((item) => {
							return {
								name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
								item,
							};
						});
						if(addressType === "homeAddress") {
							this.addressSuggestions = suggestions;
							this.disableAddress = true;
						} else {
							this.taxAddressSuggestions = suggestions;
							this.disableTaxAddress = true;
						}
					} else {
						if(addressType === "homeAddress") {
							this.addressSuggestions = [];
						} else {
							this.taxAddressSuggestions = [];
						}
						this.addressNotFound = true;
					}
					this.fetchingAddresses = false;
				}).catch(() => {
					this.fetchingAddresses = false;
					this.addressNotFound = true;
				})
		},

		setAddress(data, addressType) {
			if (Object.keys(data).length > 0) {
				const address = {
					address1 : data.streetLine,
					city : data.city,
					state : data.state,
					postalCode : data.zipcode,
				}
				if(addressType === "homeAddress") {
					this.homeAddress = {
						...this.homeAddress,
						...address
					};
					this.selectedAddress =  data.streetLine;
				} else {
					this.info1099Data.tax1099Address =  {
						...this.info1099Data.tax1099Address,
						...address
					};
					this.selectedTaxAddress =  data.streetLine;
				}
			}
			this.addressSuggestions = [];
			this.taxAddressSuggestions = [];
		},

		async Update1099InfoData(e) {
			e.preventDefault();
			this.loading = true;
			if (this.refileIRS) {
				await this.changeRefileIRS();
			}
			if (this.physicalDelivery) {
				await this.changePhysicalDelivery();
			}
			if(this.is1099InfoUpdated || this.isSame1099AndDeliveryAddressFlagChanged || this.ssnCorrected || this.updateName || this.isNameSync) {

				let payload = {
					isSame1099AndDeliveryAddress: this.isSame1099AndDeliveryAddress,
					partnerID: this.partnerID, 
					taxYear: this.$props.data.year, 
					description: this.description,
					ssnCorrected:this.ssnCorrected,
					updateMainName:this.updateMainName,
					firstName: this.info1099Data.firstName,
					lastName: this.info1099Data.lastName,
					updateName:this.updateName,
					isNameSync:this.isNameSync
				};	
				let oldData = {}, updatedData = {};
				
				if(Number(this.info1099Data?.totalTaxableAmount) !== Number(this.$props.data?.totalTaxableAmount)) {
					oldData = {
						...oldData,
						totalTaxableAmount: this.$props.data.totalTaxableAmount,
					},
					updatedData = {
						...updatedData,
						totalTaxableAmount: this.info1099Data.totalTaxableAmount,
					}
				}
				
				if(this.isSame1099AndDeliveryAddress) {
					payload = {
						...payload,
						address: this.homeAddress,
					}
					
					if(!isEqual(this.$props.data.tax1099Address, this.homeAddress)) {
						oldData = {
							...oldData,
							address: this.$props.data.tax1099Address,
						},
						updatedData = {
							...updatedData,
							address: this.homeAddress,
						}
					}
					
				} else {
					payload = {
						...payload,
						tax1099Address: this.info1099Data.tax1099Address,
					}
					
					if(!isEqual(this.info1099Data.tax1099Address,  this.$props.data.tax1099Address)) {
						oldData = {
							...oldData,
							address: this.$props.data.tax1099Address,
						},
						updatedData = {
							...updatedData,
							address: this.info1099Data.tax1099Address,
						}
					}
					
					if(!isEqual(this.$props.address, this.homeAddress)) {
						payload = {
							...payload,
							updatedHomeAddress: this.homeAddress
						}
					}
				}
				if(this.ssnCorrected){
					oldData = {
							...oldData,
							ssn: encode(this.$props.data.ssn.replace(/-/g, '')),
						},
						updatedData = {
							...updatedData,
							ssn: encode(this.info1099Data.ssn.replace(/-/g, '')),
						}
					payload = {
						...payload,
						ssn : await encrypt(this.info1099Data.ssn.replace(/-/g, ''))   
					}
				}
				if(this.updateName || this.isNameSync){
					oldData = {
							...oldData,
							firstName: this.$props.data.firstName,
							lastName:this.$props.data.lastName
						},
						updatedData = {
							...updatedData,
							firstName: this.info1099Data.firstName,
							lastName:this.info1099Data.lastName
						}
					payload = {
						...payload,
						ssn : await encrypt(this.info1099Data.ssn.replace(/-/g, ''))   
					}
				}
							
				let result = await restApi.post("/tax/updateTax1099Info", encodeWithParam({...payload, oldData, updatedData}))
				
				if(!this.refileIRS && result.data.success && Object.keys(updatedData).length) {
					await restApi.post('/tax/corrected/1099nec', encodeWithParam({ taxYear:this.$props.data.year, partnerID:this.partnerID, userID: this.$props.userID}))
				}
			}
			if (this.refileIRS || this.updateName) {
				await restApi.post('/tax/corrected/1099nec', encodeWithParam({ taxYear:this.$props.data.year, partnerID:this.partnerID, userID: this.$props.userID}));
			}
			this.loading = false;
			this.$emit("refetchData");
			this.closeModal();
		},

		closeModal() {
			this.resetData();
			this.$emit("click");
		},

		toggleisSame1099AndDeliveryAddress() {
			this.isSame1099AndDeliveryAddress = !this.isSame1099AndDeliveryAddress;
		},
		toggleNameCorrected(){
			this.updateMainName =!this.updateMainName
			if(this.updateMainName){
				this.isNameSync=true
			}
			else{
				this.isNameSync=false
			}
		},
		
		resetTaxableAmountToZero() {
			this.info1099Data = {
				...this.info1099Data,
				totalTaxableAmount: 0,
			}
		},

		handleAddressChange(oldText, newText, addressType = '') {
			if(addressType === "homeAddress" && this.selectedAddress?.trim() === newText.trim()) return;
			else if(addressType !== "homeAddress" && this.selectedTaxAddress?.trim() === newText.trim()) return;
			
			if (oldText !== "" && newText !== oldText) {
				if (newText !== oldText) {
					clearTimeout(this.timeout);
				}
				this.fetchingAddresses = true;
				this.timeout = setTimeout(() => {
					const value = newText;
					this.getAddressSuggestions(value, addressType);
				}, 1000);
			}
		},

		checkIs1099InfoUpdated: debounce(function (newVal, addressType) {
			if(Number(this.info1099Data.totalTaxableAmount) !== Number(this.$props.data.totalTaxableAmount)) {
				this.is1099InfoUpdated = true;
				return;
			}

			if(addressType === 'homeAddress') {
				this.is1099InfoUpdated = !(isEqual(newVal, this.$props.address)) || !(isEqual(this.info1099Data.tax1099Address, this.$props.data.tax1099Address))
			} 		
			else {
				this.is1099InfoUpdated = !(isEqual(newVal, this.$props.data.tax1099Address)) || !(isEqual(this.homeAddress, this.$props.address))
			}
		}, 300),

		poCheck(value, addressType){
			const restrictedAddress = ["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];

			if (value.split(' ').some((strItem) => restrictedAddress.includes(strItem))){
				addressType === 'home' ?  this.poErrorAddress = true : this.poErrorTaxAddress = true;
			} else {
				addressType === 'home' ?  this.poErrorAddress = false : this.poErrorTaxAddress = false;
			}
		},

		resetData() {
			this.info1099Data = {
				name: this.$props.data.name,
				firstName:this.$props.data.firstName,
				lastName:this.$props.data.lastName,
				ssn: this.$props.data.ssn,
				tax1099Address: {
					address1: this.$props.data.tax1099Address.address1,
					address2: this.$props.data.tax1099Address.address2, 
					city : this.$props.data.tax1099Address.city,
					state : this.$props.data.tax1099Address.state,
					country : this.$props.data.tax1099Address.country,
					postalCode : this.$props.data.tax1099Address.postalCode,
					
				},
				year: this.$props.data.year,
				totalTaxableAmount: this.$props.data.totalTaxableAmount,
			};
			this.isSame1099AndDeliveryAddress = this.$props.data.isSame1099AndDeliveryAddress;

			this.homeAddress = {
				address1: this.$props.address.address1,
				address2: this.$props.address.address2, 
				city : this.$props.address.city,
				state : this.$props.address.state,
				country : this.$props.address.country,
				postalCode : this.$props.address.postalCode,
			};

			this.is1099InfoUpdated = false;
			this.disableAddress = true;
			this.disableTaxAddress = true;
			this.addressSuggestions = [];
			this.taxAddressSuggestions = [];
			this.description ='';
			this.loading = false;
			this.ssnError = false;
			this.ssnErrorMsg = '';
		},

		checkName(){
			this.info1099Data.firstName = this.info1099Data.firstName.trimStart();
			this.info1099Data.lastName = this.info1099Data.lastName.trimStart();
			if(this.info1099Data.firstName.length > 2 && this.info1099Data.lastName.length > 1){
				this.updateName=true
			}
		},


	async checkSsn(val) {
      this.ssnError=false;
      this.ssnErrorMsg='';
	  this.ssnCorrected=false;
      let sanitizedSSN = val.replace(/\D/g, '');
      const maxLength = 11;
      if (val.length <= maxLength) {
        this.ssnError=true;
        if (sanitizedSSN.length > 3 && sanitizedSSN.length <= 5) {
          this.info1099Data.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3)}`;
        } else if (sanitizedSSN.length > 5) {
          this.info1099Data.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;
        } else {
          this.info1099Data.ssn = sanitizedSSN;
        }
      }
      else {
        this.info1099Data.ssn = val.slice(0, maxLength)
      }
	  if (val.length === maxLength || sanitizedSSN.length == 9) {
        let ssnCheck = await encrypt(val.replace(/\D/g, ''))
        const data = await restApi.post("/employee/checkSSN", encodeWithParam({ ssn: ssnCheck }));
         this.ssnError=false;
		 this.ssnCorrected=true;
        if(Number(data.data.result.count) > 0){
          this.ssnError=true;
		  this.ssnCorrected=false;
          this.ssnErrorMsg='Duplicate SSN'
        }
      }
	  else{
		this.ssnError=true;
		this.ssnCorrected=false;
		this.ssnErrorMsg='Please enter valid SSN'
	  }
    },
		async updateTaxablePartnerFlags(param) {
            await restApi.post(`/tax/updateTaxablePartnerFlags`, encodeWithParam(param))
        },
		togglePhysicalDelivery(){
			this.physicalDelivery = !this.physicalDelivery;
		},
		toggleRefileIRS(){
			this.refileIRS = !this.refileIRS;
		},
        changePhysicalDelivery() {
            let param = {
                partnerID: this.partnerID,
                year: this.$props.data.year,
                update: 'nec_physical_delivery',
                nec_physical_delivery: this.physicalDelivery
            };
        this.updateTaxablePartnerFlags(param);
        },
        async changeRefileIRS() {
            let param = {
                partnerID: this.partnerID,
                year: this.$props.data.year,
                update: 'nec_refile_irs',
                nec_refile_irs: this.refileIRS
            };
            await this.updateTaxablePartnerFlags(param);
        },
	},
};
</script>
  
<style scoped>
.loader-cls{
	margin-left: 35%;
}
.loader-txt{
	margin-top: 10px;
    margin-left: 10px;
}
.checkbox-input-deposit{
	width: 16px;
	  cursor: pointer;
	  height: 15px;
	  margin-top: 2px;
  }
.ssn-error{
	width: 70%;
}
.flags, .switch-cls{
	margin-top: 20px;
}
.switch-cls label{
	margin: 5px;
}
.modal-window_overlay {
	position: fixed;
	top: 100px;
	left: 0;
	right: 0;
	bottom: 0;
	background: #0000007a;
	/* backdrop-filter: blur(10px); */
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	overflow: hidden;
	z-index: 40;
}

.modal-window_container {
	background-color: #fff;
	padding: 36px;
	width: 100%;
	max-width: 700px;
	max-height: 100%;
	border-radius: 7px;
	overflow-y: auto;
	box-sizing: border-box;
	position: relative;
	font-family: 'Open Sans';
	max-height: 90%;
}

.title-text {
	color: var(--neutral-grey-2, #2E2F32);
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

form {
	margin: 36px auto 0;
}

.form-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 18px;
}

.form-sub-header {
	margin: 20px 0 18px;
	font-size: 18px;
	color: #46474A;
	width: 100%;
	font-family: 'Open Sans';
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.36px;
}

.form-input {
	width: 48%;
	min-width: 200px;
	margin-bottom: 24px;
}

.custom-input {
	width: 100%;
	position: relative;
}

.custom-input .right-button {
	position: absolute;
	top: 46%;
	right: 12px;
	color: #0071DC;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 7px;
}

.form-description-text {
	color: #2E2F32;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	width: 100%;
	margin-bottom: 7px;
}

.footer-buttons {
	display: flex;
	gap: 24px;
	margin-top: 40px;
}

.suggestion-box-container {
	width: 48%;
	position: relative;
}

.box {
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
	font-family: "Open Sans";
	position: absolute;
	background-color: white;
	margin-top: -15px;
	z-index: 5;
	cursor: pointer;
}

.suggestion-item {
	padding: 5px;
	cursor: pointer;
	margin-bottom: 0;
}

.suggestion-item:hover {
	background-color: lightgray;
}</style>